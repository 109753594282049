import { Component } from '@angular/core';
import {
  IonApp,
  IonRouterOutlet,
  IonFooter,
  IonButton,
  IonIcon,
  IonButtons,
  IonToolbar,
} from '@ionic/angular/standalone';
import { register } from 'swiper/element/bundle';
import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';
import { DebugService } from 'src/app/services/debug/debug.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgForOf } from '@angular/common';
import { filter } from 'rxjs';
import { SpielerverwaltungService } from 'src/app/services/spieler/spielerverwaltung.service';
import { Spieler } from 'src/app/services/spieler/spieler';

type NavigationItem = {
  title: string;
  href: string;
  iconPath: string;
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonToolbar,
    IonButtons,
    IonIcon,
    IonButton,
    IonFooter,
    IonApp,
    IonRouterOutlet,
    NgForOf,
  ],
})
export class AppComponent {
  public readonly navigation: NavigationItem[] = [
    {
      title: 'Truhen',
      href: '/truhen',
      iconPath: '/assets/icon/menu/truhen.png',
    },
    {
      title: 'Tracking',
      href: '/tracking',
      iconPath: '/assets/icon/menu/zettel.png',
    },
    {
      title: 'Kampf',
      href: '/',
      iconPath: '/assets/icon/menu/kampf.png',
    },
    {
      title: 'Beutel',
      href: '/charakter',
      iconPath: '/assets/icon/menu/rucksack.png',
    },
    {
      title: 'Schmiede',
      href: '/schmiede',
      iconPath: '/assets/icon/menu/hammer.png',
    },
  ];

  public activeItem: NavigationItem | undefined;

  constructor(
    private debugService: DebugService,
    private spielerverwaltung: SpielerverwaltungService,
    private router: Router
  ) {
    // macht alle Icons für alle unterliegenden Componenten verfügbar (also für alle)
    // scheinbar ein Bug mit Standalone Components
    // siehe https://github.com/ionic-team/ionicons/issues/1032#issuecomment-1817947776
    addIcons(allIcons);

    // registriert Swiper für die gesamte App (darf nur ein mal gerufen werden)
    register();

    this.registriereNavigationListener();
    this.registriereDebugListener();

    // Zeigt TimeWarp Ergebnis für den MainCharacter
    this.spielerverwaltung.zeigeErgebnis(Spieler.initialesKampfErgebnis);
  }

  public navigate(target: NavigationItem): void {
    this.router.navigate([target.href]);
  }

  private registriereNavigationListener(): void {
    this.router.events
      .pipe(filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        this.activeItem = this.navigation.find((item) => item.href == ev.url);
      });
  }

  private registriereDebugListener(): void {
    window.addEventListener('keydown', (ev) => {
      if (
        ev.ctrlKey &&
        ev.altKey &&
        ev.shiftKey &&
        !ev.repeat &&
        ev.key == 'K'
      ) {
        this.debugService.oeffneDebugMenu();
      }
    });
  }
}
