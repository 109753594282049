import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  private static readonly originalConsoleError: (...data: any[]) => void =
    console.error;

  public constructor(
    private zone: NgZone,
    private alertController: AlertController
  ) {
    this.zone.onError.subscribe((e) => this.handleError(e));
    window.addEventListener('error', (ev) => this.handleError(ev));
    window.addEventListener('unhandledrejection', (ev) => this.handleError(ev));
    console.log('GlobalErrorHandler created!');

    console.error = (...data: any[]) => {
      this.handleError(data);
    };
  }

  public handleError(error: unknown): void {
    GlobalErrorHandler.originalConsoleError(
      'Error from GlobalErrorHandler',
      error
    );

    let errorString: string = error + '\n' + JSON.stringify(error, null, 1);

    this.alertController
      .create({
        header: 'Fehler',
        message: 'Irgendwas ist schief gegangen:',
        buttons: [
          {
            text: 'OK',
            role: 'ok',
          },
        ],
        inputs: [
          {
            type: 'textarea',
            name: 'data',
            placeholder: 'Fehlerdetails',
            label: 'Fehlerdetails',
            value: errorString,
            attributes: {
              readonly: true,
            },
          },
        ],
      })
      .then((dialog) => {
        dialog.present();

        try {
          navigator.clipboard.writeText(errorString).catch(() => {});
        } catch (err) {
          console.log(err);
        }
      });
  }
}
