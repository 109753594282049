import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'rucksack',
    loadComponent: () =>
      import('./pages/rucksack/rucksack.page').then((m) => m.RucksackPage),
  },
  {
    path: 'charakter',
    loadComponent: () =>
      import('./pages/charakter/charakter.page').then((m) => m.CharakterPage),
  },
  {
    path: 'lagerfeuer',
    loadComponent: () =>
      import('./pages/lagerfeuer/lagerfeuer.page').then(
        (m) => m.LagerfeuerPage
      ),
  },
  {
    path: 'truhen',
    loadComponent: () =>
      import('./pages/truhen/truhen.page').then((m) => m.TruhenPage),
  },
  {
    path: 'tracking',
    loadComponent: () =>
      import('./pages/tracking/tracking.page').then((m) => m.TrackingPage),
  },
  {
    path: 'schmiede',
    loadComponent: () =>
      import('./pages/schmiede/schmiede.page').then((m) => m.SchmiedePage),
  },
  {
    path: 'statistik',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/statistik/statistik.page').then(
            (m) => m.StatistikPage
          ),
      },
      {
        path: 'graph/:id',
        loadComponent: () =>
          import('./pages/statistik/graph/graph.page').then((m) => m.GraphPage),
      },
    ],
  },
  {
    path: 'pet-gym',
    loadComponent: () =>
      import('./pages/pet-gym/pet-gym.page').then((m) => m.PetGymPage),
  },

  // Alle ungültigen zur Hauptseite bringen
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
