<ion-app>
  <main>
    <ion-router-outlet></ion-router-outlet>
  </main>

  <ion-footer class="bottom-navigation">
    <ion-buttons>
      <ion-button
        *ngFor="let link of this.navigation"
        (click)="this.navigate(link)"
        [class.active]="link === this.activeItem"
      >
        <div class="icon-over-text">
          <img [src]="link.iconPath" alt="" />
          <span class="title">{{ link.title }}</span>
        </div>
      </ion-button>
    </ion-buttons>
  </ion-footer>
</ion-app>
